<template>
  <div class="information-menu">
    <div class="information-container">
      <img class="information-svg" src="../assets/SVG/INFORMATION.svg" alt="INFORMATION">
    </div>

    <div class="information-content">
      <h2 class="title">遠藤昭主宰《８１５展》</h2>
      <h3 class="title">堀江実 新作短編映画 上映会</h3>

      <div class="event-details">
        <p class="details-text"><strong>■日時：</strong>2024年8月15日(木) 18時過ぎより</p>
        <p class="details-text"><strong>■会場：</strong>好文画廊（日本橋浜町)</p>
        <p class="details-text"><strong>■上映作品：</strong></p>
      </div>

      <div class="movie">
        <p class="movie-title">1.『裂あるいは残』</p>
        <p class="film-info">2024 / 4K / B&W / Standard / Stereo / 10min.</p>
        <p class="staff-credit">企画・原案：遠藤昭</p>
        <p class="staff-credit">監督・出演・撮影・編集：堀江実</p>
        <p class="content-description">美術家・遠藤昭が1960年代のスケッチブックに書き残していた短編映画の企画を60年の時を越えて映像化。堀江実が自作自演に励む時代錯誤(?)な実験映画。</p>
      </div>

      <div class="movie">
        <p class="movie-title">2.『渡良瀬』</p>
        <p class="film-info">2024 / 4K / Color / 16:9 / Stereo / 6min.</p>
        <p class="staff-credit">原作：遠藤昭「記憶と記録」<span class="indent">(文芸同人誌『815』Vol.3 所収)</span></p>
        <p class="staff-credit">声の出演：遠藤昭</p>
        <p class="staff-credit">監督・撮影・編集：堀江実</p>
        <p class="content-description">終戦の日の「記憶」が当事者によって語られ、かつての面影を探して故郷の風景は「記録」されていく。打ち上げ花火の轟音は弔いの願いとなり、足利の夜空に降り注ぐ。</p>
      </div>
    </div>

    <div class="info-close-button-container">
      <img :src="require('../assets/SVG/X-BLACK.svg')" alt="Close" @click="infoclosePage">
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    infoclosePage() {
      window.history.back();
    }
  }
}
</script>

<style scoped>
.information-menu {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.information-container {
  width: 80%;
  max-width: 640px;
  margin-bottom: 20px;
  text-align: center;
}

.information-content {
  width: 100%;
  max-width: 640px;
  text-align: justify;
  
  /* border-color: rgb(133, 28, 149);
  border-style: solid;
  border-width: 1px; */
}

.title {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: bold;
  color: black; 
  text-align: left; 
  margin-bottom: 1rem;
  margin-top: 0.25rem;
  font-size: 1.2rem;
  /* border-color: rgb(149, 28, 40);
  border-style: solid;
  border-width: 1px; */

}

.event-details {
  margin-bottom: 0px;  
  /* border-color: rgb(149, 28, 40);
  border-style: solid;
  border-width: 1px; */
}

.details-text {
  margin-bottom: 0;
  margin-top: 0.1rem;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 700;
  font-style: normal;
  /* border-color: rgb(48, 28, 149);
  border-style: solid;
  border-width: 1px; */
}

.movie {
  margin-bottom: 20px;
  text-indent: 2em;
}

.movie-title {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: bold;
  text-indent: 0;
  line-height: 0.9; /* 行間を狭く設定 */
}

.film-info {
  font-family: "Crimson Text";
  font-style: italic;
  font-size: 1rem;
  line-height: 0.5; /* 行間を狭く設定 */
}

.staff-credit {
  font-family: "Zen Kaku Gothic New", sans-serif;
  line-height: 0.5; 
}

.content-description {
  font-family: "Shippori Mincho", serif;
  text-indent: 0em;
  padding-left: 2em;
}

.indent {
  display: inline-block;
  text-indent: 0rem;
}

.information-svg {
  margin-top: 10vh;
}

.info-close-button-container {
  position: fixed;
  top: 6.8vh;
  right: 4vw;
  z-index: 1000;
}

.info-close-button-container img {
  width: 55px;
  height: 55px;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .information-content {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .movie {
    margin-bottom: 15px;
  }
.movie {
  margin-bottom: 20px;
  text-indent: 2em;
}
  .info-close-button-container img {
    width: 45px;
    height: 45px;
  }

  .information-svg {
    margin-top: 15vh;
    width: 35%;
  }

  .title {
    font-size: 1.0rem;
  }
  
  .movie-title {
    font-size: 1rem;
  }

  .staff-credit {
    text-indent: 2em;
  }

  .indent {
    text-indent: 5rem;
    display: block;
    line-height: 2;
  }
}
</style>
