import { createRouter, createWebHistory } from 'vue-router'
import TopComponent from '../components/TopComponent.vue'
import ProfileMenu from '../components/ProfileMenu.vue'
import FilmDetail from '../components/FilmDetail.vue' // 作品紹介ページのコンポーネントをインポート
import InformationComponent from '../components/InformationComponent.vue'
import ContactComponent from '../components/ContactComponent.vue'

const routes = [
  {
    path: '/',
    name: 'top',
    component: TopComponent
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileMenu
  },
  {
    path: '/film/:id', // 動的セグメントを使用して、作品のIDをURLパラメータとして受け取る
    name: 'filmDetail',
    component: FilmDetail
  },
  {
    path: '/information', // INFORMATION
    name: 'information',
    component: InformationComponent
  },
  {
    path: '/contact', // CONTACT
    name: 'contact',
    component: ContactComponent
  }

]

const router = createRouter({
  history: createWebHistory('/'), //createWebHistory('/testsite_/')
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { left: 0, top: 0 }
    }
  }
})

export default router