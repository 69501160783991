<template>
  <div class="container">

<div class="top">
    <div class="top_image">
      <img class="responsive-top-image" src="../assets/TOP.jpg" alt="Top Image">
    </div>

    <div class="bottom">
      <div class="bottom_left"></div>
      <div class="bottom_center">
        <router-link class="link" to="/profile">
        <img class="responsive-MHfilms-image" src="../assets/SVG/MinoruHorieFILMS.svg" alt="Minoru Horie FILMS">
        </router-link>
      </div>
      <div class="bottom_right"></div>
    </div>
</div>

  </div>
</template>


<script>
export default {
  mounted() {
    this.updateVh();
    window.addEventListener('resize', this.updateVh);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateVh);
  },
  methods: {
    updateVh() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }
}

  </script>
  
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; 
  width: 80%; 
  margin: 0 auto;
  /* border-color: rgb(0, 0, 0);
  border-style: solid;
  border-width: 1px;  */
}

.link {
  display: flex; 
  justify-content: center; 
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border-color: rgb(3, 80, 223);
  border-style: solid;
  border-width: 2px;  */
  width: 100%; 
}


.top_image {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border-color: rgb(3, 80, 223);
  border-style: solid;
  border-width: 2px;  */

  width: 100%; 
  max-width: 740px;
  min-width: 220px;
}

.bottom {
  display: flex; 
  justify-content: space-around; 
  width: 100%;
  max-width: 740px;
  min-width: 220px;
}

.bottom_center {
  align-items: center;
  /* border-color: rgb(133, 13, 13);
  border-style: solid;
  border-width: 2px;  */
  height: auto;
  width: 60%;
  max-width: 740px;
  min-width: 220px;
}

.bottom_left,.bottom_right {
  align-items: center;
  /* border-color: rgb(133, 13, 13);
  border-style: solid;
  border-width: 2px;  */
  width: 20%;
}

.responsive-top-image {
  width: 100%;
  height: auto;
  object-fit: contain; 
  margin: 10px 0;
}

.responsive-MHfilms-image {
  width: 100%; 
  height: auto; 
  object-fit: contain; 
  margin-top: 5.0rem ;
  /* border-color: rgb(133, 13, 13);
  border-style: solid;
  border-width: 2px;  */

}



@media screen and (max-width: 1080px) {

  .responsive-MHfilms-image {
  width: 100%; 
  height: auto; 
  object-fit: contain; 
  margin: 1.75rem 0; 
  }

  .top_image {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; 
  max-width: 640px;
}

  .bottom_left,.bottom_right {
  align-items: center;
  width: 30%;
}

.bottom_center {
  align-items: center;
  width: 40%; 
  max-width: 700px;
  min-width: 200px;
}
}

@media screen and (max-width: 480px) {
  .responsive-MHfilms-image {
  width: 100%; 
  height: auto; 
  object-fit: contain; 
  margin: 1.75rem 0; 
  }

  .bottom_left,.bottom_right {
  align-items: center;
  width: 30%;
}
.bottom_center {
  align-items: center;
  width: 40%; 
  max-width: 700px;
  min-width: 200px;
}
}

</style>
