import { createStore } from 'vuex'

export default createStore({
  state: {
    scrollPositions: {}
  },
  mutations: {
    setScrollPosition (state, { name, position }) {
      state.scrollPositions[name] = position
    }
  }
})
