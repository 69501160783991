<template>
  <div class="feature-films">
    <FilmThumbnail :films="reversedFilms" :routeName="$route.name" :componentName="'FeatureFilms'" />

  </div>
</template>

<script>
import FilmThumbnail from './FilmThumbnail.vue';

export default {
  name: 'FeatureFilms',
  components: {
    FilmThumbnail
  },
  data() {
    return {
      films: [
        { id: 1, title: '熊になる ', image: require('@/assets/film_01.jpg') },
        { id: 2, title: 'みずち ', image: require('@/assets/film_02.jpg') },
        { id: 3, title: '首くくり栲象の庭 ', image: require('@/assets/film_03.jpg') },
        { id: 4, title: '黒沢美香＆ダンサーズ\n 稽古ドキュメント2017', image: require('@/assets/film_04.jpg') },
        { id: 5, title: 'Ｋと流刑地', image: require('@/assets/film_05.jpg') },
        { id: 6, title: 'The Making of サマースプリング', image: require('@/assets/film_06.jpg') },
        { id: 7, title: 'やもりの行方 ', image: require('@/assets/film_07.jpg') },
        { id: 8, title: 'イバラ・夜の祈り ', image: require('@/assets/film_08.jpg') }
      ]
    };
  },
  computed: {
    reversedFilms() {
      return [...this.films].reverse();
    }
  }
};
</script>

<style scoped>
.feature-films {
  background-color: black;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: white;
}
</style>