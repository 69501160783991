<template>
  <div class="profile-menu">

    <div class="image-container">
      <img class="director-photo" src="../assets/Director.jpg" alt="Director">
      <img class="profile-svg" src="../assets/SVG/PROFILE.svg" alt="Profile">
    </div>

    <div class="profile-content">
      <p>
      <span class="profile-name">堀江 実 (ほりえ・みのる)</span>
      <span class="profile-description">1980年東京生まれ。高校時代より映画を志し、大学在学中は映画監督・柳町光男に学ぶ。
      2003年、長谷川和彦の助監督候補に選出され、以降数年に渡って劇映画『連合赤軍』の脚本執筆に参加。20代半ばより本格的に映画制作を開始し、
      初監督作品『熊になる』(2008)、自ら出演も果たした『みずち』(2013)、初ドキュメンタリー作品『首くくり栲象の庭』(2016)などを発表。
      近年は個人映画のスタイルに傾斜し、劇映画から記録映画まで横断的に活動している。
      </span>
      </p>
    </div>





    <div class="films-title">
    <img class="feature-svg" src="../assets/SVG/FEATUREFILMS.svg" alt="Feature Films">
    </div>
    <!-- 長編コンポーネント -->
    <div class="films-container">
    <FeatureFilms />
    </div>
    <div class="films-title">
    <img class="short-svg" src="../assets/SVG/SHORTFILMS.svg" alt="Short Films">
    </div>
    <!-- 短編コンポーネント -->
    <div class="films-container">
    <ShortFilms />
    </div>




  <div class="menu-items">  
    <span><router-link to="/information"><img class="info-svg" src="../assets/SVG/INFORMATION.svg" alt="Information"></router-link></span>
    <span><router-link to="/contact"><img class="contact-svg" src="../assets/SVG/CONTACT.svg" alt="Contact"></router-link></span>
    <span><router-link to="/"><img class="top-svg" src="../assets/SVG/TOP.svg" alt="Top"></router-link></span>
  </div>


</div>
</template>

<script>
import FeatureFilms from './FeatureFilms.vue';
import ShortFilms from './ShortFilms.vue';


export default {
  name: 'ProfileMenu',
  components: {
    FeatureFilms,
    ShortFilms
  },
  data() {
    return {

    };
  },
  methods: {
    updateVh() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },

  },
  mounted() {
    this.updateVh();
    window.addEventListener('resize', this.updateVh);

    //復元
    const position = this.$store.state.scrollPositions[this.$route.name]
    if (position !== undefined) {
      window.scrollTo(position, 0)
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateVh);

    //保存
    this.$store.commit('setScrollPosition', {
      name: this.$route.name,
      position: window.scrollX
    })
  }
};

</script>


<style scoped>

.profile-menu {
  width: 90%;
  max-width: 1200px;
  margin: 5% auto;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.director-photo {
  width: 45%;
  min-width: 280px; 
  max-width: 300px; 
  margin: 5px; 
  /* border-color: rgb(239, 9, 158);
  border-style: solid;
  border-width: 1px;   */
}

.profile-svg {
  
  margin-top: 20px;
}

.profile-content {
  /* border-color: rgb(255, 0, 93); */
  /* border-style: solid; */
  margin: 10px auto; 
  width: 80%; 
  text-align: justify; 
  line-height: 1.75em; 
  letter-spacing: 1px;
}

.profile-content p {
  margin-bottom: 30px; /* Before 0 */
}

.profile-name {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: 0; 
  margin-right: 1em; 
  letter-spacing: 0px;
}

.profile-description {
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-style: normal;
  margin: 0; 
  letter-spacing: 0.02rem;
}


.films-title img {
  margin: 10px 0 0px 0;  
  }

.films-container {
    margin-left: 3px;  
    margin-right: 3px;  
    margin-bottom: 10px;
    padding-top: 15px;
    background-color:rgb(34, 30, 31) ;
    /* border-color: rgb(239, 9, 136);
    border-style: solid;
    border-width: 1px;   */
  }

.menu-items {
  display: flex;
  flex-wrap: nowrap; 
  margin: 20px 0;
}
.menu-items span {
  margin-right: 2rem;
}

/* Mobile */
@media screen and (max-width: 480px) {

  .director-photo {
  width: 50%;
  min-width: 200px; 
  max-width: 280px; 
  margin-top: 10px; 
  margin-bottom: 1.5em;
  /* border-color: rgb(239, 9, 158);
  border-style: solid;
  border-width: 1px;   */
}

  .profile-content {
  line-height: 1.5em; 
  width: 90%;
  /* border-color: rgb(239, 9, 158);
  border-style: solid;
  border-width: 1px;   */
  }

  .profile-svg {
  width: 24%;
  margin: 3px;
  /* border-color: rgb(239, 9, 158);
  border-style: solid;
  border-width: 1px; */
}

.feature-svg {
  width:42%;
    /* border-color: rgb(239, 9, 158);
  border-style: solid;
  border-width: 1px; */
}

.short-svg {
  width:35%;
  /* border-color: rgb(239, 9, 158);
  border-style: solid;
  border-width: 1px; */
}

.info-svg,.contact-svg,.top-svg {
  width:80%;
  /* border-color: rgb(239, 9, 158);
  border-style: solid;
  border-width: 1px; */
}
.menu-items {
  display: flex;
  flex-wrap: nowrap;
  margin: 15px 0;
}
.menu-items span {
  margin-right: 0rem;
}
}
</style>
