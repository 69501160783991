<template>
  <div class="thumbnail-container" ref="container">
    <router-link
      v-for="film in films"
      :key="film.id"
      :to="{ name: 'filmDetail', params: { id: film.id }}"
      class="thumbnail-item"
    >
      <img :src="film.image" :alt="film.title" class="film-thumbnail">
      <div class="film-title" v-html="formattedTitle(film)"></div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'FilmThumbnail',
  props: {
    films: {
      type: Array,
      required: true
    },
    routeName: { 
      type: String,
      required: true
    },
    componentName: { 
    type: String,
    required: true
  }
  },
  methods: {
    formattedTitle(film) {
      return film.title.split('\n').map(line => `<span style="white-space: nowrap;">${line}</span>`).join('<br>');
    }
  },
  mounted() {
  this.$nextTick(() => {

    const position = this.$store.state.scrollPositions[this.componentName] 
    if (position !== undefined) {
      this.$refs.container.scrollLeft = position;
    }
  });
},
beforeUnmount() {

  const position = this.$refs.container.scrollLeft;
  this.$store.commit('setScrollPosition', {
    name: this.componentName, 
    position: position
  })
},

};
</script>

<style scoped>
.thumbnail-container {
  display: flex;
  overflow-x: auto;
  gap: 55px; /* サムネイル間隔 */
  padding-left: 45px;
  padding-right: 40px;
  padding-top: 5px;
  padding-bottom: 0px;  
  align-items: stretch;  
  background-color:rgb(34, 30, 31);
  /* border-color: rgb(239, 9, 136);
  border-style: solid;
  border-width: 1px;   */
}

.thumbnail-item {
  flex: 0 0 auto;
  width: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
}

.thumbnail-item:visited {
  color: white;
}

.film-thumbnail {
  width: 100%;
  height: auto;
  border-color: white;
  border: 0.5px solid;
  margin-bottom: 5px;
}

.film-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  min-height: 4em;
  font-size: 0.6em;
  color: inherit;
  justify-content: center;
  align-items: center;
  text-align: center;

}

.nowrap {
  white-space: nowrap;
}

@media screen and (max-width: 480px) {
  .thumbnail-container {
  display: flex;
  overflow-x: auto;
  gap: 45px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 0px;
  align-items: stretch;
  background-color:rgb(34, 30, 31);

}


  .thumbnail-item {
  flex: 0 0 auto;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white; 
}

.film-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  min-height: 4em;
  font-size: 0.5em;
  color: inherit;
  justify-content: center;
  align-items: center;
  text-align: center;
}
}
</style>
