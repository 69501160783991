<template>
<div class="MH-contact-container">

  <div class="MH-contact">
    <img class="MH-contact-svg" src="../assets/SVG/MinoruHorieCONTACT.svg" alt="CONTACT">
  </div>

  <div class="MH-contact-footer">
  <img class="MH-mail-svg" src="../assets/SVG/MAIL_icon.svg" alt="mail">&nbsp;<a href="mailto:genfilm1980@gmail.com" class="black-link">genfilm1980@gmail.com</a>
  <img class="X-svg" src="../assets/SVG/X_icon.svg" alt="X">&nbsp;<a href="https://x.com/genhorie?s=20" class="black-link">@genhorie </a>
  </div>

  <div class="Copyright">Copyright&ensp;&#169;2019 minoruhorie.com&ensp;All&ensp;Right&ensp;Reserved.</div>
  <div class="MH-contact-close-button-container">
  <img :src="require('../assets/SVG/X-BLACK.svg')" alt="Close" @click="ContactClosePage" class="ContactClose">
  </div>

</div>
  </template>
  
  <script>
export default {
  mounted() {
    this.updateVh();
    window.addEventListener('resize', this.updateVh);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateVh);
  },
  methods: {
    updateVh() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    ContactClosePage() {
      window.history.back();
    }
  }
}

  </script>
  
  <style scoped>

.MH-contact-container{
  position:absolute;
  object-fit:contain;
  background-size:cover; 
  background-repeat: no-repeat;
  background-position:50%;
  /* height: calc(var(--vh, 1vh) * 100); */
  height:100dvh ;
  background-image: url('../assets/CONTACT.jpg');
  width:100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.MH-contact-svg{
  width: 60%;
  max-width: 230px;
  height: auto;
}
.MH-mail-svg{
  width: 20px;
  height: auto;
}
.X-svg{
  width: 20px;
  height: auto;
}
.ContactClose{
  width: 55px;
  height: auto;
}

.MH-contact{
  display: flex;
  width: 100%;
  margin: 10% auto;
  margin-top: 15vh;
  margin-bottom: 1vh;
  justify-content: center;
  max-width: 400px;
  /* border-color: rgb(33, 9, 218);
  border-style: solid;
  border-width: 1px;  */
}


.MH-contact-footer{
  display: flex;
  align-items: center;
  justify-content: center; 
  font-family: "Crimson Text",sans-serif;
  font-weight: 600;
  font-style: italic;
  font-size:1em;
  letter-spacing: 1px;
  color: rgb(0, 0, 0);
  width: 100%;
  margin: 5% auto; 
  margin-bottom: 10px;
  margin-top: 0px;
  max-width: 400px;    
  /* border-color: rgb(13, 164, 2);
  border-style: solid;
  border-width: 1px;  */
}

.black-link{
  text-decoration: none;
  color:black;
  margin-right: 1em;
}

.black-link:visited {
  color: rgb(0, 0, 0);
}

.MH-contact-close-button-container{
  position: fixed; 
  top: 7vh; 
  right: 5vw; 
  z-index: 1000; 
  width: 30px;
}

.Copyright{
  display:block;
  align-items: center;
  text-align: center;
  font-family: "Crimson Text",sans-serif;
  font-weight: 600;
  font-style: italic;
  font-size:1em;
  letter-spacing: 1px;
  color: rgb(0, 0, 0);
  width: 100%;
  margin: 5% auto; 
  margin-top: 0px;
  max-width: 400px;
  white-space: nowrap;
  /* border-color: rgb(0, 0, 0);
  border-style: solid;
  border-width: 1px;  */
}

@media screen and (max-width: 480px) {

  .MH-contact-container{
  position:absolute;
  object-fit:contain;
  background-size:cover; 
  background-repeat: no-repeat;
  background-position:51%;
  /* height: calc(var(--vh, 1vh) * 100); */
  height:100dvh;
  background-image: url('../assets/CONTACT_S.jpg');
  width:100%;
  padding: 0;
  margin: 0;
  /* border-color: rgb(4, 230, 53);
  border-style: solid;
  border-width: 5px;  */

}
  .ContactClose{
  width: 40px;
  height: auto;
}

  .MH-contact-close-button-container{
  position: fixed;
  top: 4vh;
  right: 10vw; 
  z-index: 1000; 
  width: 30px;
}

.MH-contact{
  display: flex;
  width: 80%;
  margin: 10% auto;
  margin-top: 15vh;
  margin-bottom: 1vh;
  justify-content: center;
  max-width: 480px;
  /* border-color: rgb(218, 9, 204);
  border-style: solid;
  border-width: 1px;  */
}

.MH-contact-footer{
  display: flex;
  align-items: center;
  justify-content: center; 
  font-family: "Crimson Text",sans-serif;
  font-weight: 600;
  font-style: italic;
  font-size:0.8em;
  letter-spacing: 1px;
  color: rgb(0, 0, 0);
  width: 80%;
  margin: 5% auto; 
  margin-bottom: 10px;
  margin-top: 20px;
  max-width: 400px;    
  /* border-color: rgb(13, 164, 2);
  border-style: solid;
  border-width: 1px;  */
}

.Copyright{
  display:block;
  align-items: center;
  text-align: center;
  font-family: "Crimson Text",sans-serif;
  font-weight: 600;
  font-style: italic;
  font-size:0.8em;
  letter-spacing: 1px;
  color: rgb(0, 0, 0);
  width: 100%;
  margin: 0% auto; 
  margin-top: 0px;
  max-width: 400px; 
  white-space: nowrap;
  /* border-color: rgb(0, 0, 0);
  border-style: solid;
  border-width: 1px;  */
}
}
  </style>